@use 'variables' as vars;


//Purpose: my general button style
@mixin generalButton($textColor, $textHoverColor, $hoverBackgroundColor){
    display: block;
    font-size: 24px;
    font-family: vars.$sansFont;
    font-style: normal;
    text-decoration: none;
    color: $textColor;
    border-top: 2px solid $textColor;
    border-bottom: 2px solid $textColor;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all .25s;
    text-transform: uppercase;
    cursor: pointer;
    &:hover{
        letter-spacing: 5px;
        text-decoration: underline;
        text-decoration-color: $textHoverColor;
        background-color: $hoverBackgroundColor;
        color: $textHoverColor;
    }
}

@mixin sectionTitle(){
    font-family: vars.$sansFont;
    font-size: 1.8rem;
    color: vars.$lightPurple;
    padding: 20px 0px;
    padding-left: 30px;
    font-weight: lighter;
    background-color: vars.$white;
    border-bottom: 1px solid vars.$platinum;
    margin-top: 10px;
    .sectionSubtitle{
        font-size: 1.2rem;
        margin-left: 10rem;
    }
}

@mixin roundedboxShadow(){
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid vars.$platinum;
    &:hover{
        box-shadow: none;
        background-color: white;
    }
}

@mixin imageCard($cardWidth, $descriptionHeight, $titleSize, $descriptionSize){
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
    .card{
        background-color: vars.$white;
        @include roundedboxShadow();
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: $cardWidth;
        .cardImage {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
            overflow: hidden;
            position: relative;
            transition: filter 0.5s cubic-bezier(.63,.61,.62,.61);;
            &::before {
              content: "";
                display: block;
              padding-top: 56.25%; 
            }
            @media(min-width: 40rem) {
              &::before {
                padding-top: 66.6%; 
              }
            }
        }
        .cardContent{
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            padding: 1rem;
            min-height: 28vh;
            color:grey;
            .cardTitle{
                color: vars.$purple;
                font-family: vars.$sansFont;
                font-size: $titleSize;
                font-weight: 300;
                letter-spacing: 2px;
                text-transform: uppercase;
                font-style: normal;
                border-bottom: 1px solid vars.$platinum;
            }
            .cardText{
                padding: 20px;
                flex: 1 1 auto;
                font-size: $descriptionSize;
                line-height: 1.5;
                font-weight: bolder;
                margin-bottom: 1.25rem;
                font-family: vars.$serifFont;
                color: vars.$darkPurple;
                height: $descriptionHeight;
            }
            .buttonWrapper a{
                margin: 40px 0px;
                @include generalButton(vars.$lightPurple, vars.$white, vars.$lightPurple);
                margin-bottom:0px;
            }
        }
    }
}