@use 'variables' as vars;
@use 'mixin';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');


.scheduleSection{
    background: url('../Images/background.png') ;
    font-family: vars.$sansFont;
    color: white;
    padding: 50px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2{
        align-items: center;
        text-transform: uppercase;
        font-size: 3rem;
        text-align: center;
        margin-bottom: 40px;
    }
    h4{
        width: 60%;
        font-size: 1rem;
        text-align: center;
        margin: 60px 0px;
    }
    .buttonWrapper a{
        @include mixin.generalButton(vars.$white, vars.$darkPurple, vars.$white)
    }
}
@media(max-width: 702px){
    .scheduleSection{
        h2{
            font-size: 2rem;
            margin-bottom: 20px;
        }
        h4{
            width: 70%;
            font-size: 0.8rem;
            margin: 30px 0px;
        }
    }
}