@use 'variables' as vars;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

#footer{
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
    background-color: vars.$white;
    color: vars.$lightPurple;
    margin: 0px;
    padding-top: 20px;
    width: 100vw;
    font-family: vars.$sansFont;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    #footerLogo{
        width: 2ren;
        height: 2rem;
        margin: 20px 0px;
    }
    #footerLinks{
        $imageOffset:100px;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: start;
        width: calc(100% - $imageOffset);
        margin-right: $imageOffset;
        i{
            color: #4b214030;   
        }
        h2{
            font-weight: lighter;
            color: vars.$lightPurple;
        }
        li{
            padding-right: 30px;
            border-right: 1px solid vars.$platinum;
            width: 30%;
            height: 300px;
        }
        .subLinks{
            font-weight: 300;
            color: vars.$lightPurple;
            margin: auto;
            margin-top: 20px;
            width: 90%;
            p{
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                .weekDay{
                    width: 150px;
                }
            }
            i{
                margin-right: 10px;
                width: 30px
            }
            a{
                color: vars.$purple;
                &:hover{
                    color: vars.$darkGrey;
                }
            }
        }
    }
    hr{ 
        border: 0;
        width: 98%;
        height: 5px;
        margin: auto;
        background-image: linear-gradient(to right, #4b214030, #4b214075, #4b214030);
    }
    #hrImageHolder{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-right: 1%;
        h3{
            color: vars.$lightPurple;
            text-transform: none;
            min-width: 350px;
        }
        #footerHrImage{
            height: 150px;
            -webkit-filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
            filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
        }
    }
   #footerNav{
        margin: 0px;
        padding: 10px;
        color:vars.$darkGrey;
        font-size: 10px;
        width: 100vw;
        display: flex;
        justify-content: center;
        a{
            margin-left: 10px;
            color:vars.$darkGrey;
        }
   } 
}

//handling the end hr
@media (max-width: 770px){
    #hrImageHolder{
        #footerHrImage{
            height: 100px !important;
        }
        h3{
            min-width: 200px !important;
        }
    }
}
@media (max-width: 516px){
    #hrImageHolder{
        #footerHrImage{
            height: 70px !important;
        }
        h3{
            min-width: 100px !important;
            font-size: smaller;
        }
    }
}
//handling the 3 NavLink Li's
@media (max-width: 1280px){
    #footerLinks{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        li{
            display: flex;
            flex-direction: column;
            width: 100% !important;
            align-items: center !important;
            border: none;
            border-bottom: 1px solid vars.$platinum;
            border-right: 0px !important;
            padding: 20px;
            height: fit-content !important;
        }
    }
}
@media (max-width: 435px){
    #footerLinks{
        width: 100% !important;
        margin: 0px !important;
        li{
            width: 100%;
        }
    }
    .subLinks{
        width: 100% !important;
    }
    .weekDay{
        width: 100px;
    }
}