//Color Schema
    //Main color
    $purple: #6B2D5C;
    //Darker version of main color
    $darkPurple:#4b2140;
    $lightPurple: #7a5070; //what the footer filters to 
    $lightestPurple: #a1859b;
    //Series of Greys
    $white: #f4f0f5;
    $darkWhite: #f2ecf3;
    $platinum: #E5E6E4;
    $greenGrey: #CFD2CD;
    $darkGrey: #9b9898;
    //Complements
    $sage: #B6C8A9;
    $sageMint: #C8EAD3;
    $mint: #CFFFE5;
    $salmon: #D6A2AD;
    //Option 2
    $sage: #C6EBBE;
    $celadon: #a9dbb8;
    $blue: #406e8e;
    $pink: #F8E5EE;

//Font Collections
    $sansFont: Poppins,sans-serif;
    $serifFont: 'Playfair Display', serif;




