@use 'variables' as vars;
@use 'mixin';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

$sectionHeight : 70vh;
$resizedSectionHeight: 50vh;
$phoneSectionHeight: 60vh;

.videoHeader{
    height: $sectionHeight;
    width: 100%;
    margin-top: 0px;
    position: relative;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
    .fullSectionVid{
        height: $sectionHeight;
        width: 100%;
        object-fit: cover; 
        position: absolute;
        top: 0px;
        z-index: 0;
        filter: brightness(50%);
    }
    .videoOverlayText{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        margin: 2% 0px;
        z-index: 3 !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: vars.$white;
        font-family: vars.$sansFont;
        text-align: center;
        h2{
            text-transform: uppercase;
            font-size: 4.5vw;
        }
        h3{
            text-transform: uppercase;
            font-size: 3vw;
            font-family: vars.$sansFont;
            color:white;
        } 
        h4{
            margin-top: 5vh;
            text-align: center;
            font-size: 2vw;
            margin-bottom: 2vh;
            font-family: vars.$serifFont;
        }
    }
    .buttonWrapper a{
        @include mixin.generalButton(vars.$white, vars.$darkPurple, vars.$white)
    }
}


//TODO this doesnt work on ultra wide monitors, the text overlay overflows

@media (max-width: 770px){
    .videoHeader{
        filter: brightness(45%);
        height: $resizedSectionHeight;
        .fullSectionVid{
            height: $resizedSectionHeight;
        }
    }
    .videoOverlayText{

        h4{
            font-size: 4vw !important;
        }
    }
}
@media(max-width: 450px){
    .videoHeader{
        filter: brightness(65%);
        height: $phoneSectionHeight;
        .fullSectionVid{
            height: $phoneSectionHeight
        }
    }
    .videoOverlayText{
        h4{
            font-size: 5vw !important;
        }
    }
}

