@use 'variables' as vars;
@use 'mixin';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');



.formHolder{
    color: vars.$purple;
    font-family: vars.$sansFont;
    background-color: vars.$white;
    margin: auto;
    margin-bottom: 60px;
    width: 90%;

    .formTitle{
        @include mixin.sectionTitle();
        margin-bottom: 60px;
    }
    #formField{
        margin: 0px;
        padding: 0px;
        width: 100%;
    }
    div.inputBox{
        margin:0px;
        padding:0px;
        box-sizing:border-box;
        display:flex;
        justify-content: center;
        min-height: 100px;
        flex-direction:column;
        gap: 10px;
        width: 50%;
        position:relative;
    }
    .inputAndTitle
    {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 20px;
        color:vars.$darkPurple;
        flex-wrap: wrap;
        min-width: 300px;
        width: 100%;
        padding: 0px;
        margin: 0px;
    }
    .inputBox input
    {
        left: 20px;
        position:absolute;
        width: 100%;
        padding: 10px;
        border: 1px solid vars.$darkPurple;
        border-radius: 5px;
        background: none;
        outline:none;
        color:vars.$purple;
        font-size: 1.3em;
        font-style: normal;
        font-weight: 400;
    }
    .inputBox textarea
    {
        left: 20px;
        position:absolute;
        width: 100%;
        padding: 10px;
        border: 1px solid vars.$darkPurple;
        border-radius: 5px;
        background: none;
        outline:none;
        color:vars.$purple;
        font-size: 1rem; 
        font-style: normal;
        font-weight: 400;
    }
    .inputBox span
    {
        position:absolute;
        left:20px;
        padding:10px;
        pointer-events: none;
        font-size: 1.3em;
        text-transform:uppercase;
        font-style: normal;
        font-weight: 400;
    }
    .label
    {
        width: 200px;
        font-style: normal;
        font-weight: 400;
        font-size: 1.3rem;
    }
    .inputBox input:focus ~span
    {
        transform: translateX(10px) translateY(-35px);
        font-size:0.75em;
        transition: 0.5s;
    }
    input[type="submit"]
    {
        padding: 20px 10px;
        border: 1px solid vars.$darkPurple;
        border-radius: 5px;
        color:vars.$purple;
        font-size: 1em;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        font-style: normal;
        font-weight: 400;
        width:60%;
        text-transform: uppercase;
        margin: 0 20%;
        margin-top: 30px;

    }
    input[type="submit"]:hover
    {
        color: white;
        background-color:vars.$purple;
    }
    input,
    select {
        width: 100%;
        display: inline-block;
    }
    input[type='submit'] {
        font-size: 1.5em;
    }
    .mini-image {
        height: auto;
        width: 100%;
        border-radius: 5px;
        border: 1px solid rgb(206, 238, 227, 1) ;
    }
    .required-field {
        color: red;
    }
    /* clicked CSS */
    .focusInSpan
    {
        transform: translateX(10px) translateY(-35px);
        font-size:0.75em;
    }
}