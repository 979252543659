@forward 'homeLinksList';
@use 'variables' as vars;
@use 'sass:color';
@use 'mixin';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

#homeFounderInformation, .imageTextBasic{
    display: flex;
    flex-direction: row;
    padding:30px;
    background-color: white;
    justify-content: space-around;
    align-items: start;
    color: vars.$darkPurple;
    font-family: vars.$sansFont;
    border-top: 1px solid vars.$platinum;
    border-radius: 30px;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
    width: 98%;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    #homeFounderPicture{
        min-width: 800px;
    } 
    .nonFramedImage{
        width: 600px;
    }
    #homeFounderInformationTextContainer, .informationTextContainer{
        padding: 30px;
       h2{
            font-size: 2.5rem;
            font-family: vars.$sansFont;
            margin-bottom: 30px;
            color: vars.$lightPurple;
       }
       h3{
            font-size: 1.8rem;
            font-family: vars.$sansFont;
            margin-left: 30px;
            margin-bottom: 30px;
            padding: 10px 0px;
            color: vars.$lightPurple;
            border-bottom: 1px solid vars.$platinum;
            i{
                font-style: italic;
                color: darken(vars.$lightPurple, 5%)
            }
       }
       h4{
            font-size: 1.4rem;
            font-family: vars.$serifFont;
            margin-left: 60px;
            margin-bottom: 10px;
            font-weight: bold;
       }
       .buttonWrapper a{
        margin: 40px 0px;
        margin-left: 30px;
        @include mixin.generalButton(vars.$lightPurple, vars.$white, vars.$lightPurple)
    }
    }
}
//Reszing for the homefounder information
@media (max-width: 1566px){
    #homeFounderInformation, .imageTextBasic {
        flex-direction: column;
    }
    #homeFounderPicture{
        margin: auto;
       display: flex;
       flex-direction: column;
       align-items: center;
    }
    #homeFounderInformationTextContainer, .informationTextContainer{
        text-align: center;
        margin: auto;
        h3{
            margin-left: 0px !important;
        }
        h4{
            margin-left: 0px !important;
        }
    }

}
@media(max-width: 876px){
    #homeFounderPicture{
        align-items: center;
        margin: auto !important;
        min-width: 200px !important;
        .framedImage{
            margin: 0px;
            width: 500px;
            height: 500px;
        }
    }
    .nonFramedImage{
        width: 400px;
    }
    #homeFounderInformationTextContainer, .informationTextContainer{
        h2,h3{
            font-size: 1.5rem !important;
        }
        h4{
            font-size: 1rem !important;
        }
        .buttonWrapper{
            a{
                margin-left: 0px !important;
            }
        }
    }
}
@media(max-width: 600px){
    .nonFramedImage{
        width: 300px !important;
    }
    #homeFounderPicture{
        align-items: center;
        margin: 0px;
        max-width: 400px;
        .framedImage{
            margin: 0px;
            width: 400px;
            height: 600px;
            max-width: 250px !important;
        }
        .frameNote{
            font-size: 1rem;
        }
    }
}
@media(max-width: 375px){
    .nonFramedImage{
        width: 200px !important;
    }
    #homeFounderPicture{
        align-items: center;
        margin: 0px;
        min-width: none !important;
        .framedImage{
            margin: 0px;
            width: 300px;
            height: 450px;
        }
    }
    #homeFounderInformationTextContainer, .informationTextContainer{
        h2,h3{
            font-size: 1rem !important;
        }
        h4{
            font-size: 0.7rem !important;
        }
        .buttonWrapper{
            a{
                font-size: 1.3rem !important;
                margin-left: 0px !important;
            }
            margin: 20px 0px;
            margin-left: 0px !important;
        }
    }
}

//Image card Component styled specifically for homepage
#homeAboutMeQuickLinks{
   .sectionTitle{
        @include mixin.sectionTitle();
    }
    ul{
        list-style: none;
        width:100%;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: space-around;
        background-color: vars.$white;
        padding-bottom: 20px;
        li{
            @include mixin.imageCard(600px, 200px, 1.5rem, 1rem);
            //putting in all the potential images
            //TODO change this 
            .aboutMeFocusImg{
                background-image: url('../Images/areasOfFocusFinal.png') ;
            }
            .aboutMePhilosophyImg{
                background-image: url('../Images/aboutMePhilosophyFinal.png')
            }
            .aboutMeApproachImg{
                background-image: url('../Images/clinicalApproaches.png')
            }
        }
    }
}
@media(max-width:1850px)
{
    #homeAboutMeQuickLinks{
        ul{
            li{
                @include mixin.imageCard(500px, 250px, 1rem, 1rem);
            }
        }
    }
}
@media(max-width:1561px)
{
    #homeAboutMeQuickLinks{
        ul{
            li{

                @include mixin.imageCard(400px, 250px, 1rem, 0.8rem)
            }
        }
    }
}
@media(max-width:1294px)
{
    #homeAboutMeQuickLinks{
        ul{
            display: flex;
            flex-direction: column;
            li{

                @include mixin.imageCard(950px, 100px, 1.5rem, 1rem)
            }
        }
    }
}
@media(max-width:950px)
{
    #homeAboutMeQuickLinks{
        .sectionSubtitle{
            margin: 0px !important;
            width: 100%;
        }
        ul{
            display: flex;
            flex-direction: column;
            li{

                @include mixin.imageCard(500px, 250px, 1rem, 1rem);
            }
        }
    }
}
@media(max-width:524px)
{
    #homeAboutMeQuickLinks{
        ul{
            display: flex;
            flex-direction: column;
            li{

                @include mixin.imageCard(300px, 260px, 1rem, 0.8rem);
            }
        }
    }
}



#homeLocationQuickLinks{
    .sectionTitle{
         @include mixin.sectionTitle();
     }
     ul{
         list-style: none;
         width:100%;
         padding: 0px;
         margin: 0px;
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
         background-color: vars.$white;
         padding-bottom: 20px;
         li{
             @include mixin.imageCard(900px, 100px, 1.5rem, 1rem);
             //putting in all the potential images
             //TODO change this 
             .locationInPersonImage{
                background-image: url('../Images/office2.jpg');
             }
             .locationTelehealthImage{
                background-image: url('https://images.unsplash.com/photo-1507297171131-a3274a17fe9b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80');
             }
         }
     }
 }
 @media(max-width:1842px)
 {
     #homeLocationQuickLinks{
         ul{
             li{
 
                @include mixin.imageCard(600px, 100px, 1.5rem, 1rem);
             }
         }
     }
 }
 @media(max-width:1270px){
    #homeLocationQuickLinks{
        ul{
            li{

                @include mixin.imageCard(500px, 100px, 1.5rem, 1rem);
            }
        }
    }
} 
@media(max-width:1100px){
    #homeLocationQuickLinks{
        .sectionSubtitle{
            margin: 0px !important;
            width: 100%;
        }
        ul{
            display: flex;
            flex-direction: column;
            li{

                @include mixin.imageCard(600px, 100px, 1.5rem, 1rem);
            }
        }
    }
} 
@media(max-width:670px){
    #homeLocationQuickLinks{
        ul{
            display: flex;
            flex-direction: column;
            li{

                @include mixin.imageCard(350px, 100px, 1.5rem, 1rem);
            }
        }
    }
} 




.basicCardsHolder{
    display: flex;
    flex-direction: column;
    padding:30px;
    background-color: white;
    justify-content: space-around;
    align-items: start;
    color: vars.$darkPurple;
    font-family: vars.$sansFont;
    border-top: 1px solid vars.$platinum;
    border-radius: 30px;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
    width: 98%;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    .basicCardsTitle{
            padding: 30px;
           h2{
                font-size: 2.5rem;
                font-family: vars.$sansFont;
                margin-bottom: 30px;
                color: vars.$lightPurple;
           }
           h3{
                font-size: 1.8rem;
                font-family: vars.$sansFont;
                margin-left: 30px;
                margin-bottom: 30px;
                padding: 10px 0px;
                color: vars.$lightPurple;
                border-bottom: 1px solid vars.$platinum;
                line-height: normal;
                i{
                    font-style: italic;
                    color: darken(vars.$lightPurple, 5%)
                }
           }
           h4{
                font-size: 1.4rem;
                font-family: vars.$serifFont;
                margin-left: 60px;
                margin-bottom: 10px;
                font-weight: bold;
           }
           .buttonWrapper a{
            margin: 40px 0px;
            margin-left: 30px;
            @include mixin.generalButton(vars.$lightPurple, vars.$white, vars.$lightPurple)
        }
    }
    ul{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        list-style: none;
        margin: auto;
        padding: 0px;
        font-weight: 900;
        width: 90%;
        flex-wrap: wrap;
        li{
            @include mixin.imageCard(400px, 200px, 1rem, 1rem);
        }
    }
    @media (max-width: 1000px) {
        .basicCardsTitle{
            padding: 10px;
            h2{
                font-size: 1.5rem !important;
            }
            h3{
                margin-left: 0px;
                font-size: 1rem !important;
            }
            }
            .cards__item{
                @include mixin.imageCard(300px, 200px, 0.8rem, 0.8rem);
            }
    }
    //one day i will change these from absolute links to unsplash
    .card__image--attachment 
    {
        background-image: url('https://images.unsplash.com/photo-1495558685573-aba7573d9c01?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2048&q=80');
    }
    .card__image--anxiety 
    {
        background-image: url('https://images.unsplash.com/photo-1595178156906-2396ef837b0f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
    }
    .card__image--depression 
    {
    background-image: url('https://images.unsplash.com/photo-1613312328068-c9b6b76c9e8a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
    }
    .card__image--adjustment 
    {
        background-image: url('https://images.unsplash.com/photo-1586162544998-979c72b307bc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
    }
    .card__image--trauma 
    {
        background-image: url("https://images.unsplash.com/photo-1627305412843-61b0824e6f59?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
    }
    .card__image--communication 
    {
        background-image: url('https://images.unsplash.com/photo-1683209616453-619caef10a00?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
    }
    .card__image--life 
    {
        background-image: url('https://images.unsplash.com/photo-1581573950452-5a438c5f390f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2029&q=80');
    }
    .card__image--relationship 
    {
        background-image: url('https://images.unsplash.com/photo-1541089255538-4ea146c8866d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
    }
    .card__image--lgbt 
    {
        background-image: url('https://images.unsplash.com/photo-1620862658014-b7cb662d3df4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
    }
    .card__image--kink 
    {
        background-image: url('https://images.unsplash.com/photo-1583876677554-5a233be562ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
    }
}

.basicFullWidthText{
    width: 80%;
    margin: auto;
    padding: 30px;
    font-size: 1.5rem;
    line-height: 2;
    font-weight: bold;
    font-family: vars.$serifFont;
    @media (max-width: 1000px) {
        font-size: 1rem !important;
    }
};

