//TODO make this a mixin function that accepts any image
@use 'variables' as vars;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');




//todo: make this accept any url
$picUrl : url('../Images/ashleyPicRecent.jpg');
$borderColor: vars.$white;
$borderRadius : 30px;

.framedImage{
    display: grid;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.124);
    grid-template-columns: max-content auto;
    grid-template-rows: auto max-content;
    background: #333 $picUrl top;
    background-size: cover;
    width: 700px;
    height: 700px;
    padding: 1.8rem;
    font-family: vars.$sansFont;
    border-radius: $borderRadius;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    &:before,
    &:after 
    {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border: 3px solid $borderColor;
        border-top-right-radius: $borderRadius ;
        border-bottom-right-radius: $borderRadius;
    }
    &:before 
    {
        grid-row: 1 / 2;
        border-right: none;
        border-bottom: none;
        border-top-left-radius: $borderRadius ;
        border-top-right-radius: 0px ;
    }	
    &:after 
    {
        grid-row: 1 / span 2;
        border-left: none;
    }
    .frameNote 
    {
        margin: 1rem 1rem -.5rem 0;
        font-weight: 400;
        text-transform: uppercase;
        text-align: left;
        font-size: 2rem;
        max-width: 100%;
        color: vars.$lightPurple;
        background-color: vars.$white;
        padding: 20px 10px;
        border: 2px solid $borderColor;
        border-radius: $borderRadius;
        box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
    }
}