@use 'variables' as vars;
@use 'mixin';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');


.heroSlider{
    .heroHeader{
        background-color: vars.$lightestPurple;
        color: vars.$white;
        margin: 0px;
        padding: 10px;
        margin-bottom: -20px;
        h5{
            text-align: center;
            font-size: 2vw;
            font-family: vars.$sansFont;
        }
        p{
            text-align: center;
            font-size: 1vw;
            font-family: vars.$serifFont;
            padding-bottom: 10px;
        }
    }
    .carousel{
        img{
            height: 70vh; 
         }
    }
};


.imageTextSection{
    display: flex;
    flex-direction: row;
    padding:60px;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    color: vars.$darkPurple;
    font-family: vars.$sansFont;
    border-top: 1px solid vars.$platinum;
    border-radius: 30px;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
    width: 98%;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    #mapHolder{
        min-width: 400px;
        height: 500px;
        border: 2px solid vars.$lightestPurple;
        border-radius: 20px;
    }
    #telehealthImage{
        width: 600px;
        height: 500px;
        border: 2px solid vars.$lightestPurple;
        border-radius: 20px;
    } 

    #imageTextSectionText{
        padding: 30px;
        padding-left: 60px;
       h2{
            font-size: 2.5rem;
            font-family: vars.$sansFont;
            margin-bottom: 20px;
            color: vars.$lightPurple;
       }
       h3{
            font-size: 1.5rem;
            font-family: vars.$sansFont;
            margin-left: 30px;
            margin-bottom: 30px;
            padding: 10px 0px;
            color: vars.$lightPurple;
            border-bottom: 1px solid vars.$platinum;
            i{
                font-style: italic;
                color: darken(vars.$lightPurple, 5%)
            }
       }
       h4{
            font-size: 1.4rem;
            font-family: vars.$serifFont;
            margin-left: 60px;
            margin-bottom: 10px;
            font-weight: bold;
       }
    }
}

@media(max-width: 1345px){
    #imageTextSectionText{
       h2{
            font-size: 2rem !important;
       }
       h3{
            font-size: 1rem !important;
       }
       h4{
            font-size: 1rem !important;
       }
    }
    #mapHolder, #telehealthImage{
        width: 300px !important;
        height: 300px !important;
        object-fit: cover !important;

    }
}
@media(max-width: 900px){
    .imageTextSection{
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }
    #imageTextSectionText{
        padding: 0px !important;
        margin: 0px;
        margin-top: 10px;
        width: 100%;
        h2,h4,h3{
            margin: 0px !important;
            margin-bottom: 10px !important;
            padding: 0px;
        }
        h2{
            font-size: 1.3rem !important;
        }
        h4,h5{
            font-size: 1rem !important;
        }
    }
}

@media(max-width: 400px){
    #mapHolder{
        min-width: 300px !important;
    }
}

