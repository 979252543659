
//Base Page Styling
@forward 'navbar';
@forward 'footer';
@forward 'video';
@forward 'homepage';
@forward 'framedPicture';
@forward 'scheduleSection';
@forward 'insurance';
@forward 'contact';
@forward 'carousel';
@forward 'services';

@use 'variables' as vars;
//Universal Styling
*{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
}
body{
    background-color: vars.$white;
    overflow-x: hidden;
}
.App{
    background-color: vars.$white;
}
.tab{
    display: inline-block;
    margin-left: 20px;
}

