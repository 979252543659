@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
nav {
  height: 4rem;
  width: 100vw;
  padding: 0px 10px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 1005;
  background-color: white;
  /*Stying for small screens*/
}
nav a:not(.homeLink):not(.navButton) {
  text-decoration: none;
  text-transform: uppercase;
  color: #6B2D5C;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  margin: auto 20px;
  display: inline-block;
  position: relative;
}
nav a:not(.homeLink):not(.navButton)::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #4b2140;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
nav a:not(.homeLink):not(.navButton):hover {
  color: #4b2140;
  font-weight: bold;
}
nav a:not(.homeLink):not(.navButton):hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
nav .navLink.active {
  color: #9b9898 !important;
  font-weight: bold !important;
}
nav .navButton {
  text-decoration: none;
  text-transform: uppercase;
  color: #6B2D5C;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  background-color: white;
  border: 1.5px solid #6B2D5C;
  margin: 0px 10px;
  padding: 5px 15px;
  border-radius: 30px;
  position: relative;
}
nav .navButton::after {
  background-color: #6B2D5C;
  border-radius: 30px;
  content: "";
  display: block;
  height: 40px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(4px, 6px);
  transition: transform 0.5s ease-out;
  z-index: -1;
}
nav .navButton:hover {
  outline: 0;
  color: #4b2140;
  background-color: #f4f0f5;
}
nav .navButton:hover:after {
  transform: translate(0, 0);
  background-color: #4b2140;
}
nav #navHome {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
}
nav #navHome a {
  text-decoration: none;
}
nav #navLogo {
  height: 3rem;
  width: 3.3rem;
}
nav #navLogo:hover {
  animation: spin 9.5s linear infinite;
}
nav h4 {
  text-decoration: none;
  text-transform: uppercase;
  color: #6B2D5C;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 200;
  position: relative;
  margin-bottom: -5px;
}
nav h4::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #6B2D5C;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
nav h4:hover {
  font-weight: bold;
}
nav h4:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
nav .homeLink {
  margin: auto 20px;
}
nav .linkHolder {
  flex-direction: row;
  font-family: Poppins, sans-serif;
  display: flex;
  list-style: none;
  padding: 0 0.4vw;
  padding-left: 0;
  margin: 0;
  justify-content: space-around;
  align-items: center;
}
nav .hamburger div {
  width: 35px;
  height: 3px;
  background: #6B2D5C;
  margin: 5px;
  transition: all 0.3s ease;
}
nav .hamburger div:hover {
  color: #4b2140;
}
nav .hamburger {
  display: none;
}
nav .hiddenImage {
  display: none;
}
@media (max-width: 1364px) {
  nav {
    /*Animating Hamburger Icon on Click*/
  }
  nav .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
  }
  nav .toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  nav .toggle .line2 {
    transition: all 0.7s ease;
    width: 0;
  }
  nav .toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  nav .linkHolder {
    position: fixed;
    top: 0px;
    margin: 0px;
    margin-top: 4rem;
    left: 0px;
    background: #f4f0f5;
    height: 60vh !important;
    width: 100%;
    flex-direction: column;
    transition: all 1s ease-out;
    pointer-events: none;
    opacity: 0;
  }
  nav .linkHolder.open {
    pointer-events: all;
    height: 100vh;
    opacity: 1;
  }
  nav .linkHolder li {
    opacity: 0;
  }
  nav .linkHolder li.fading {
    opacity: 1;
  }
}

#hamburgerGraphicRight, #hamburgerGraphicLeft {
  display: none;
  height: 5vh;
  -webkit-filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
  filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
}

#hamburgerGraphicRight {
  bottom: calc(10px + 12vh);
  left: 20px;
}

#hamburgerGraphicLeft {
  top: calc(10px + 10vh);
  right: 20px;
}

.imageFading {
  display: inline-block !important;
  position: absolute;
}

@media (max-width: 448px) {
  nav {
    height: 6rem;
  }
  .linkHolder {
    margin-top: 6rem !important;
  }
  #navHome {
    flex-direction: column !important;
  }
}
@media (max-width: 624px) {
  #hamburgerGraphicRight {
    bottom: calc(10px + 12vh);
    left: 20px;
  }
  #hamburgerGraphicLeft {
    top: calc(10px + 10vh);
    right: 20px;
  }
}
@media (max-width: 502px) {
  #hamburgerGraphicRight, #hamburgerGraphicLeft {
    height: 7vh;
  }
}
#footer {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  background-color: #f4f0f5;
  color: #7a5070;
  margin: 0px;
  padding-top: 20px;
  width: 100vw;
  font-family: Poppins, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
#footer #footerLogo {
  width: 2ren;
  height: 2rem;
  margin: 20px 0px;
}
#footer #footerLinks {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  width: calc(100% - 100px);
  margin-right: 100px;
}
#footer #footerLinks i {
  color: rgba(75, 33, 64, 0.1882352941);
}
#footer #footerLinks h2 {
  font-weight: lighter;
  color: #7a5070;
}
#footer #footerLinks li {
  padding-right: 30px;
  border-right: 1px solid #E5E6E4;
  width: 30%;
  height: 300px;
}
#footer #footerLinks .subLinks {
  font-weight: 300;
  color: #7a5070;
  margin: auto;
  margin-top: 20px;
  width: 90%;
}
#footer #footerLinks .subLinks p {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
#footer #footerLinks .subLinks p .weekDay {
  width: 150px;
}
#footer #footerLinks .subLinks i {
  margin-right: 10px;
  width: 30px;
}
#footer #footerLinks .subLinks a {
  color: #6B2D5C;
}
#footer #footerLinks .subLinks a:hover {
  color: #9b9898;
}
#footer hr {
  border: 0;
  width: 98%;
  height: 5px;
  margin: auto;
  background-image: linear-gradient(to right, rgba(75, 33, 64, 0.1882352941), rgba(75, 33, 64, 0.4588235294), rgba(75, 33, 64, 0.1882352941));
}
#footer #hrImageHolder {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 1%;
}
#footer #hrImageHolder h3 {
  color: #7a5070;
  text-transform: none;
  min-width: 350px;
}
#footer #hrImageHolder #footerHrImage {
  height: 150px;
  -webkit-filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
  filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
}
#footer #footerNav {
  margin: 0px;
  padding: 10px;
  color: #9b9898;
  font-size: 10px;
  width: 100vw;
  display: flex;
  justify-content: center;
}
#footer #footerNav a {
  margin-left: 10px;
  color: #9b9898;
}

@media (max-width: 770px) {
  #hrImageHolder #footerHrImage {
    height: 100px !important;
  }
  #hrImageHolder h3 {
    min-width: 200px !important;
  }
}
@media (max-width: 516px) {
  #hrImageHolder #footerHrImage {
    height: 70px !important;
  }
  #hrImageHolder h3 {
    min-width: 100px !important;
    font-size: smaller;
  }
}
@media (max-width: 1280px) {
  #footerLinks {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  #footerLinks li {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    align-items: center !important;
    border: none;
    border-bottom: 1px solid #E5E6E4;
    border-right: 0px !important;
    padding: 20px;
    height: fit-content !important;
  }
}
@media (max-width: 435px) {
  #footerLinks {
    width: 100% !important;
    margin: 0px !important;
  }
  #footerLinks li {
    width: 100%;
  }
  .subLinks {
    width: 100% !important;
  }
  .weekDay {
    width: 100px;
  }
}
.videoHeader {
  height: 70vh;
  width: 100%;
  margin-top: 0px;
  position: relative;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}
.videoHeader .fullSectionVid {
  height: 70vh;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  z-index: 0;
  filter: brightness(50%);
}
.videoHeader .videoOverlayText {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  margin: 2% 0px;
  z-index: 3 !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #f4f0f5;
  font-family: Poppins, sans-serif;
  text-align: center;
}
.videoHeader .videoOverlayText h2 {
  text-transform: uppercase;
  font-size: 4.5vw;
}
.videoHeader .videoOverlayText h3 {
  text-transform: uppercase;
  font-size: 3vw;
  font-family: Poppins, sans-serif;
  color: white;
}
.videoHeader .videoOverlayText h4 {
  margin-top: 5vh;
  text-align: center;
  font-size: 2vw;
  margin-bottom: 2vh;
  font-family: "Playfair Display", serif;
}
.videoHeader .buttonWrapper a {
  display: block;
  font-size: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-decoration: none;
  color: #f4f0f5;
  border-top: 2px solid #f4f0f5;
  border-bottom: 2px solid #f4f0f5;
  padding: 10px;
  padding-top: 16px;
  letter-spacing: 2px;
  transition: all 0.25s;
  text-transform: uppercase;
  cursor: pointer;
}
.videoHeader .buttonWrapper a:hover {
  letter-spacing: 5px;
  text-decoration: underline;
  text-decoration-color: #4b2140;
  background-color: #f4f0f5;
  color: #4b2140;
}

@media (max-width: 770px) {
  .videoHeader {
    filter: brightness(45%);
    height: 50vh;
  }
  .videoHeader .fullSectionVid {
    height: 50vh;
  }
  .videoOverlayText h4 {
    font-size: 4vw !important;
  }
}
@media (max-width: 450px) {
  .videoHeader {
    filter: brightness(65%);
    height: 60vh;
  }
  .videoHeader .fullSectionVid {
    height: 60vh;
  }
  .videoOverlayText h4 {
    font-size: 5vw !important;
  }
}
#homeServicesOfferedLinks #homeServicesOfferedLinksTitle {
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #7a5070;
  padding: 20px 0px;
  padding-left: 30px;
  font-weight: lighter;
  background-color: #f4f0f5;
  border-bottom: 1px solid #E5E6E4;
}
#homeServicesOfferedLinks ul {
  list-style: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: #f4f0f5;
  padding-bottom: 20px;
}
#homeServicesOfferedLinks ul .largeLinkCard {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #6B2D5C;
  font-family: Poppins, sans-serif;
  border: 2px solid #E5E6E4;
  margin: 10px;
  border-radius: 20px;
  background-color: #f2ecf3;
  height: 170px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}
#homeServicesOfferedLinks ul .largeLinkCard .iconTitle {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
#homeServicesOfferedLinks ul .largeLinkCard .iconTitle img {
  width: calc(2rem + 10px);
  -webkit-filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
  filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
  border-radius: 30px;
  border: 2px solid black;
  padding: 5px;
}
#homeServicesOfferedLinks ul .largeLinkCard .iconTitle span {
  padding-left: 10px;
  font-size: larger;
  position: relative;
}
#homeServicesOfferedLinks ul .largeLinkCard .iconTitle span::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #6B2D5C;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
#homeServicesOfferedLinks ul .largeLinkCard span {
  padding: 10px 20px;
  margin-left: 10px;
}
#homeServicesOfferedLinks ul .largeLinkCard span h5 {
  font-size: 1rem;
  font-family: "Playfair Display", serif;
  font-weight: bold;
}
#homeServicesOfferedLinks ul .largeLinkCard span .hoverUnderline span {
  position: relative;
  width: fit-content;
  font-size: 1.1rem;
}
#homeServicesOfferedLinks ul .largeLinkCard span .hoverUnderline i {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
#homeServicesOfferedLinks ul .largeLinkCard:hover {
  box-shadow: none;
  background-color: white;
}
#homeServicesOfferedLinks ul .largeLinkCard:hover .iconTitle span {
  color: #6B2D5C;
  font-weight: bold;
}
#homeServicesOfferedLinks ul .largeLinkCard:hover .iconTitle span::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.fullSizeLinkCard {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #6B2D5C;
  font-family: Poppins, sans-serif;
  border: 2px solid #9b9898;
  margin: 10px;
  border-radius: 20px;
  background-color: #E5E6E4;
  height: 170px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}
.fullSizeLinkCard .iconTitle {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.fullSizeLinkCard .iconTitle img {
  width: calc(2rem + 10px);
  -webkit-filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
  filter: invert(37%) sepia(15%) saturate(1045%) hue-rotate(262deg) brightness(88%) contrast(89%);
  border-radius: 30px;
  border: 2px solid black;
  padding: 5px;
}
.fullSizeLinkCard .iconTitle span {
  padding-left: 10px;
  font-size: larger;
  position: relative;
}
.fullSizeLinkCard .iconTitle span::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #6B2D5C;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.fullSizeLinkCard span {
  padding: 10px 20px;
  margin-left: 10px;
}
.fullSizeLinkCard span h5 {
  font-size: 1rem;
  font-family: "Playfair Display", serif;
  font-weight: bold;
}
.fullSizeLinkCard span .hoverUnderline span {
  position: relative;
  width: fit-content;
  font-size: 1.1rem;
}
.fullSizeLinkCard span .hoverUnderline i {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.fullSizeLinkCard:hover {
  box-shadow: none;
  background-color: white;
}
.fullSizeLinkCard:hover .iconTitle span {
  color: #6B2D5C;
  font-weight: bold;
}
.fullSizeLinkCard:hover .iconTitle span::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (max-width: 1900px) {
  .iconTitle span {
    padding-left: 0px !important;
  }
  .largeLinkCard {
    height: 200px !important;
  }
}
@media (max-width: 1814px) {
  #homeServicesOfferedLinks ul {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 960px) {
  #homeServicesOfferedLinks ul {
    grid-template-columns: repeat(1, 1fr);
  }
  .iconTitle span {
    padding-left: 0px !important;
  }
  .largeLinkCard {
    height: 150px !important;
  }
}
#homeFounderInformation, .imageTextBasic {
  display: flex;
  flex-direction: row;
  padding: 30px;
  background-color: white;
  justify-content: space-around;
  align-items: start;
  color: #4b2140;
  font-family: Poppins, sans-serif;
  border-top: 1px solid #E5E6E4;
  border-radius: 30px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  width: 98%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
#homeFounderInformation #homeFounderPicture, .imageTextBasic #homeFounderPicture {
  min-width: 800px;
}
#homeFounderInformation .nonFramedImage, .imageTextBasic .nonFramedImage {
  width: 600px;
}
#homeFounderInformation #homeFounderInformationTextContainer, #homeFounderInformation .informationTextContainer, .imageTextBasic #homeFounderInformationTextContainer, .imageTextBasic .informationTextContainer {
  padding: 30px;
}
#homeFounderInformation #homeFounderInformationTextContainer h2, #homeFounderInformation .informationTextContainer h2, .imageTextBasic #homeFounderInformationTextContainer h2, .imageTextBasic .informationTextContainer h2 {
  font-size: 2.5rem;
  font-family: Poppins, sans-serif;
  margin-bottom: 30px;
  color: #7a5070;
}
#homeFounderInformation #homeFounderInformationTextContainer h3, #homeFounderInformation .informationTextContainer h3, .imageTextBasic #homeFounderInformationTextContainer h3, .imageTextBasic .informationTextContainer h3 {
  font-size: 1.8rem;
  font-family: Poppins, sans-serif;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 10px 0px;
  color: #7a5070;
  border-bottom: 1px solid #E5E6E4;
}
#homeFounderInformation #homeFounderInformationTextContainer h3 i, #homeFounderInformation .informationTextContainer h3 i, .imageTextBasic #homeFounderInformationTextContainer h3 i, .imageTextBasic .informationTextContainer h3 i {
  font-style: italic;
  color: #6b4662;
}
#homeFounderInformation #homeFounderInformationTextContainer h4, #homeFounderInformation .informationTextContainer h4, .imageTextBasic #homeFounderInformationTextContainer h4, .imageTextBasic .informationTextContainer h4 {
  font-size: 1.4rem;
  font-family: "Playfair Display", serif;
  margin-left: 60px;
  margin-bottom: 10px;
  font-weight: bold;
}
#homeFounderInformation #homeFounderInformationTextContainer .buttonWrapper a, #homeFounderInformation .informationTextContainer .buttonWrapper a, .imageTextBasic #homeFounderInformationTextContainer .buttonWrapper a, .imageTextBasic .informationTextContainer .buttonWrapper a {
  margin: 40px 0px;
  margin-left: 30px;
  display: block;
  font-size: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-decoration: none;
  color: #7a5070;
  border-top: 2px solid #7a5070;
  border-bottom: 2px solid #7a5070;
  padding: 10px;
  padding-top: 16px;
  letter-spacing: 2px;
  transition: all 0.25s;
  text-transform: uppercase;
  cursor: pointer;
}
#homeFounderInformation #homeFounderInformationTextContainer .buttonWrapper a:hover, #homeFounderInformation .informationTextContainer .buttonWrapper a:hover, .imageTextBasic #homeFounderInformationTextContainer .buttonWrapper a:hover, .imageTextBasic .informationTextContainer .buttonWrapper a:hover {
  letter-spacing: 5px;
  text-decoration: underline;
  text-decoration-color: #f4f0f5;
  background-color: #7a5070;
  color: #f4f0f5;
}

@media (max-width: 1566px) {
  #homeFounderInformation, .imageTextBasic {
    flex-direction: column;
  }
  #homeFounderPicture {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #homeFounderInformationTextContainer, .informationTextContainer {
    text-align: center;
    margin: auto;
  }
  #homeFounderInformationTextContainer h3, .informationTextContainer h3 {
    margin-left: 0px !important;
  }
  #homeFounderInformationTextContainer h4, .informationTextContainer h4 {
    margin-left: 0px !important;
  }
}
@media (max-width: 876px) {
  #homeFounderPicture {
    align-items: center;
    margin: auto !important;
    min-width: 200px !important;
  }
  #homeFounderPicture .framedImage {
    margin: 0px;
    width: 500px;
    height: 500px;
  }
  .nonFramedImage {
    width: 400px;
  }
  #homeFounderInformationTextContainer h2, #homeFounderInformationTextContainer h3, .informationTextContainer h2, .informationTextContainer h3 {
    font-size: 1.5rem !important;
  }
  #homeFounderInformationTextContainer h4, .informationTextContainer h4 {
    font-size: 1rem !important;
  }
  #homeFounderInformationTextContainer .buttonWrapper a, .informationTextContainer .buttonWrapper a {
    margin-left: 0px !important;
  }
}
@media (max-width: 600px) {
  .nonFramedImage {
    width: 300px !important;
  }
  #homeFounderPicture {
    align-items: center;
    margin: 0px;
    max-width: 400px;
  }
  #homeFounderPicture .framedImage {
    margin: 0px;
    width: 400px;
    height: 600px;
    max-width: 250px !important;
  }
  #homeFounderPicture .frameNote {
    font-size: 1rem;
  }
}
@media (max-width: 375px) {
  .nonFramedImage {
    width: 200px !important;
  }
  #homeFounderPicture {
    align-items: center;
    margin: 0px;
    min-width: none !important;
  }
  #homeFounderPicture .framedImage {
    margin: 0px;
    width: 300px;
    height: 450px;
  }
  #homeFounderInformationTextContainer h2, #homeFounderInformationTextContainer h3, .informationTextContainer h2, .informationTextContainer h3 {
    font-size: 1rem !important;
  }
  #homeFounderInformationTextContainer h4, .informationTextContainer h4 {
    font-size: 0.7rem !important;
  }
  #homeFounderInformationTextContainer .buttonWrapper, .informationTextContainer .buttonWrapper {
    margin: 20px 0px;
    margin-left: 0px !important;
  }
  #homeFounderInformationTextContainer .buttonWrapper a, .informationTextContainer .buttonWrapper a {
    font-size: 1.3rem !important;
    margin-left: 0px !important;
  }
}
#homeAboutMeQuickLinks .sectionTitle {
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #7a5070;
  padding: 20px 0px;
  padding-left: 30px;
  font-weight: lighter;
  background-color: #f4f0f5;
  border-bottom: 1px solid #E5E6E4;
  margin-top: 10px;
}
#homeAboutMeQuickLinks .sectionTitle .sectionSubtitle {
  font-size: 1.2rem;
  margin-left: 10rem;
}
#homeAboutMeQuickLinks ul {
  list-style: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-around;
  background-color: #f4f0f5;
  padding-bottom: 20px;
}
#homeAboutMeQuickLinks ul li {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: flex-start;
  justify-content: space-around;
}
#homeAboutMeQuickLinks ul li .card {
  background-color: #f4f0f5;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  border-radius: 20px;
  border: 3px solid #E5E6E4;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 600px;
}
#homeAboutMeQuickLinks ul li .card:hover {
  box-shadow: none;
  background-color: white;
}
#homeAboutMeQuickLinks ul li .card .cardImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
}
#homeAboutMeQuickLinks ul li .card .cardImage::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
@media (min-width: 40rem) {
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
#homeAboutMeQuickLinks ul li .card .cardContent {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  min-height: 28vh;
  color: grey;
}
#homeAboutMeQuickLinks ul li .card .cardContent .cardTitle {
  color: #6B2D5C;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-style: normal;
  border-bottom: 1px solid #E5E6E4;
}
#homeAboutMeQuickLinks ul li .card .cardContent .cardText {
  padding: 20px;
  flex: 1 1 auto;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bolder;
  margin-bottom: 1.25rem;
  font-family: "Playfair Display", serif;
  color: #4b2140;
  height: 200px;
}
#homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a {
  margin: 40px 0px;
  display: block;
  font-size: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-decoration: none;
  color: #7a5070;
  border-top: 2px solid #7a5070;
  border-bottom: 2px solid #7a5070;
  padding: 10px;
  padding-top: 16px;
  letter-spacing: 2px;
  transition: all 0.25s;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 0px;
}
#homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
  letter-spacing: 5px;
  text-decoration: underline;
  text-decoration-color: #f4f0f5;
  background-color: #7a5070;
  color: #f4f0f5;
}
#homeAboutMeQuickLinks ul li .aboutMeFocusImg {
  background-image: url("../Images/areasOfFocusFinal.png");
}
#homeAboutMeQuickLinks ul li .aboutMePhilosophyImg {
  background-image: url("../Images/aboutMePhilosophyFinal.png");
}
#homeAboutMeQuickLinks ul li .aboutMeApproachImg {
  background-image: url("../Images/clinicalApproaches.png");
}

@media (max-width: 1850px) {
  #homeAboutMeQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeAboutMeQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 500px;
  }
  #homeAboutMeQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeAboutMeQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 1850px) and (min-width: 40rem) {
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 1850px) {
  #homeAboutMeQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 250px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
@media (max-width: 1561px) {
  #homeAboutMeQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeAboutMeQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 400px;
  }
  #homeAboutMeQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeAboutMeQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 1561px) and (min-width: 40rem) {
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 1561px) {
  #homeAboutMeQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 0.8rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 250px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
@media (max-width: 1294px) {
  #homeAboutMeQuickLinks ul {
    display: flex;
    flex-direction: column;
  }
  #homeAboutMeQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeAboutMeQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 950px;
  }
  #homeAboutMeQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeAboutMeQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 1294px) and (min-width: 40rem) {
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 1294px) {
  #homeAboutMeQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 100px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
@media (max-width: 950px) {
  #homeAboutMeQuickLinks .sectionSubtitle {
    margin: 0px !important;
    width: 100%;
  }
  #homeAboutMeQuickLinks ul {
    display: flex;
    flex-direction: column;
  }
  #homeAboutMeQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeAboutMeQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 500px;
  }
  #homeAboutMeQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeAboutMeQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 950px) and (min-width: 40rem) {
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 950px) {
  #homeAboutMeQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 250px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
@media (max-width: 524px) {
  #homeAboutMeQuickLinks ul {
    display: flex;
    flex-direction: column;
  }
  #homeAboutMeQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeAboutMeQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 300px;
  }
  #homeAboutMeQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeAboutMeQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 524px) and (min-width: 40rem) {
  #homeAboutMeQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 524px) {
  #homeAboutMeQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 0.8rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 260px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeAboutMeQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
#homeLocationQuickLinks .sectionTitle {
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #7a5070;
  padding: 20px 0px;
  padding-left: 30px;
  font-weight: lighter;
  background-color: #f4f0f5;
  border-bottom: 1px solid #E5E6E4;
  margin-top: 10px;
}
#homeLocationQuickLinks .sectionTitle .sectionSubtitle {
  font-size: 1.2rem;
  margin-left: 10rem;
}
#homeLocationQuickLinks ul {
  list-style: none;
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f4f0f5;
  padding-bottom: 20px;
}
#homeLocationQuickLinks ul li {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: flex-start;
  justify-content: space-around;
}
#homeLocationQuickLinks ul li .card {
  background-color: #f4f0f5;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  border-radius: 20px;
  border: 3px solid #E5E6E4;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 900px;
}
#homeLocationQuickLinks ul li .card:hover {
  box-shadow: none;
  background-color: white;
}
#homeLocationQuickLinks ul li .card .cardImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
}
#homeLocationQuickLinks ul li .card .cardImage::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
@media (min-width: 40rem) {
  #homeLocationQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
#homeLocationQuickLinks ul li .card .cardContent {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  min-height: 28vh;
  color: grey;
}
#homeLocationQuickLinks ul li .card .cardContent .cardTitle {
  color: #6B2D5C;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-style: normal;
  border-bottom: 1px solid #E5E6E4;
}
#homeLocationQuickLinks ul li .card .cardContent .cardText {
  padding: 20px;
  flex: 1 1 auto;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bolder;
  margin-bottom: 1.25rem;
  font-family: "Playfair Display", serif;
  color: #4b2140;
  height: 100px;
}
#homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a {
  margin: 40px 0px;
  display: block;
  font-size: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-decoration: none;
  color: #7a5070;
  border-top: 2px solid #7a5070;
  border-bottom: 2px solid #7a5070;
  padding: 10px;
  padding-top: 16px;
  letter-spacing: 2px;
  transition: all 0.25s;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 0px;
}
#homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
  letter-spacing: 5px;
  text-decoration: underline;
  text-decoration-color: #f4f0f5;
  background-color: #7a5070;
  color: #f4f0f5;
}
#homeLocationQuickLinks ul li .locationInPersonImage {
  background-image: url("../Images/office2.jpg");
}
#homeLocationQuickLinks ul li .locationTelehealthImage {
  background-image: url("https://images.unsplash.com/photo-1507297171131-a3274a17fe9b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80");
}

@media (max-width: 1842px) {
  #homeLocationQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeLocationQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 600px;
  }
  #homeLocationQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeLocationQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeLocationQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 1842px) and (min-width: 40rem) {
  #homeLocationQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 1842px) {
  #homeLocationQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeLocationQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeLocationQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 100px;
  }
  #homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
@media (max-width: 1270px) {
  #homeLocationQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeLocationQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 500px;
  }
  #homeLocationQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeLocationQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeLocationQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 1270px) and (min-width: 40rem) {
  #homeLocationQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 1270px) {
  #homeLocationQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeLocationQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeLocationQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 100px;
  }
  #homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
@media (max-width: 1100px) {
  #homeLocationQuickLinks .sectionSubtitle {
    margin: 0px !important;
    width: 100%;
  }
  #homeLocationQuickLinks ul {
    display: flex;
    flex-direction: column;
  }
  #homeLocationQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeLocationQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 600px;
  }
  #homeLocationQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeLocationQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeLocationQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 1100px) and (min-width: 40rem) {
  #homeLocationQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 1100px) {
  #homeLocationQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeLocationQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeLocationQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 100px;
  }
  #homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
@media (max-width: 670px) {
  #homeLocationQuickLinks ul {
    display: flex;
    flex-direction: column;
  }
  #homeLocationQuickLinks ul li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  #homeLocationQuickLinks ul li .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 350px;
  }
  #homeLocationQuickLinks ul li .card:hover {
    box-shadow: none;
    background-color: white;
  }
  #homeLocationQuickLinks ul li .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  #homeLocationQuickLinks ul li .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 670px) and (min-width: 40rem) {
  #homeLocationQuickLinks ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 670px) {
  #homeLocationQuickLinks ul li .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  #homeLocationQuickLinks ul li .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  #homeLocationQuickLinks ul li .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 100px;
  }
  #homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  #homeLocationQuickLinks ul li .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
.basicCardsHolder {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  justify-content: space-around;
  align-items: start;
  color: #4b2140;
  font-family: Poppins, sans-serif;
  border-top: 1px solid #E5E6E4;
  border-radius: 30px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  width: 98%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
.basicCardsHolder .basicCardsTitle {
  padding: 30px;
}
.basicCardsHolder .basicCardsTitle h2 {
  font-size: 2.5rem;
  font-family: Poppins, sans-serif;
  margin-bottom: 30px;
  color: #7a5070;
}
.basicCardsHolder .basicCardsTitle h3 {
  font-size: 1.8rem;
  font-family: Poppins, sans-serif;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 10px 0px;
  color: #7a5070;
  border-bottom: 1px solid #E5E6E4;
  line-height: normal;
}
.basicCardsHolder .basicCardsTitle h3 i {
  font-style: italic;
  color: #6b4662;
}
.basicCardsHolder .basicCardsTitle h4 {
  font-size: 1.4rem;
  font-family: "Playfair Display", serif;
  margin-left: 60px;
  margin-bottom: 10px;
  font-weight: bold;
}
.basicCardsHolder .basicCardsTitle .buttonWrapper a {
  margin: 40px 0px;
  margin-left: 30px;
  display: block;
  font-size: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-decoration: none;
  color: #7a5070;
  border-top: 2px solid #7a5070;
  border-bottom: 2px solid #7a5070;
  padding: 10px;
  padding-top: 16px;
  letter-spacing: 2px;
  transition: all 0.25s;
  text-transform: uppercase;
  cursor: pointer;
}
.basicCardsHolder .basicCardsTitle .buttonWrapper a:hover {
  letter-spacing: 5px;
  text-decoration: underline;
  text-decoration-color: #f4f0f5;
  background-color: #7a5070;
  color: #f4f0f5;
}
.basicCardsHolder ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: auto;
  padding: 0px;
  font-weight: 900;
  width: 90%;
  flex-wrap: wrap;
}
.basicCardsHolder ul li {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: flex-start;
  justify-content: space-around;
}
.basicCardsHolder ul li .card {
  background-color: #f4f0f5;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  border-radius: 20px;
  border: 3px solid #E5E6E4;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 400px;
}
.basicCardsHolder ul li .card:hover {
  box-shadow: none;
  background-color: white;
}
.basicCardsHolder ul li .card .cardImage {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
}
.basicCardsHolder ul li .card .cardImage::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
@media (min-width: 40rem) {
  .basicCardsHolder ul li .card .cardImage::before {
    padding-top: 66.6%;
  }
}
.basicCardsHolder ul li .card .cardContent {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  min-height: 28vh;
  color: grey;
}
.basicCardsHolder ul li .card .cardContent .cardTitle {
  color: #6B2D5C;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-style: normal;
  border-bottom: 1px solid #E5E6E4;
}
.basicCardsHolder ul li .card .cardContent .cardText {
  padding: 20px;
  flex: 1 1 auto;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bolder;
  margin-bottom: 1.25rem;
  font-family: "Playfair Display", serif;
  color: #4b2140;
  height: 200px;
}
.basicCardsHolder ul li .card .cardContent .buttonWrapper a {
  margin: 40px 0px;
  display: block;
  font-size: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-decoration: none;
  color: #7a5070;
  border-top: 2px solid #7a5070;
  border-bottom: 2px solid #7a5070;
  padding: 10px;
  padding-top: 16px;
  letter-spacing: 2px;
  transition: all 0.25s;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 0px;
}
.basicCardsHolder ul li .card .cardContent .buttonWrapper a:hover {
  letter-spacing: 5px;
  text-decoration: underline;
  text-decoration-color: #f4f0f5;
  background-color: #7a5070;
  color: #f4f0f5;
}
@media (max-width: 1000px) {
  .basicCardsHolder .basicCardsTitle {
    padding: 10px;
  }
  .basicCardsHolder .basicCardsTitle h2 {
    font-size: 1.5rem !important;
  }
  .basicCardsHolder .basicCardsTitle h3 {
    margin-left: 0px;
    font-size: 1rem !important;
  }
  .basicCardsHolder .cards__item {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: flex-start;
    justify-content: space-around;
  }
  .basicCardsHolder .cards__item .card {
    background-color: #f4f0f5;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: 20px;
    border: 3px solid #E5E6E4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 300px;
  }
  .basicCardsHolder .cards__item .card:hover {
    box-shadow: none;
    background-color: white;
  }
  .basicCardsHolder .cards__item .card .cardImage {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.63, 0.61, 0.62, 0.61);
  }
  .basicCardsHolder .cards__item .card .cardImage::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
@media (max-width: 1000px) and (min-width: 40rem) {
  .basicCardsHolder .cards__item .card .cardImage::before {
    padding-top: 66.6%;
  }
}
@media (max-width: 1000px) {
  .basicCardsHolder .cards__item .card .cardContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    min-height: 28vh;
    color: grey;
  }
  .basicCardsHolder .cards__item .card .cardContent .cardTitle {
    color: #6B2D5C;
    font-family: Poppins, sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-style: normal;
    border-bottom: 1px solid #E5E6E4;
  }
  .basicCardsHolder .cards__item .card .cardContent .cardText {
    padding: 20px;
    flex: 1 1 auto;
    font-size: 0.8rem;
    line-height: 1.5;
    font-weight: bolder;
    margin-bottom: 1.25rem;
    font-family: "Playfair Display", serif;
    color: #4b2140;
    height: 200px;
  }
  .basicCardsHolder .cards__item .card .cardContent .buttonWrapper a {
    margin: 40px 0px;
    display: block;
    font-size: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    text-decoration: none;
    color: #7a5070;
    border-top: 2px solid #7a5070;
    border-bottom: 2px solid #7a5070;
    padding: 10px;
    padding-top: 16px;
    letter-spacing: 2px;
    transition: all 0.25s;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  .basicCardsHolder .cards__item .card .cardContent .buttonWrapper a:hover {
    letter-spacing: 5px;
    text-decoration: underline;
    text-decoration-color: #f4f0f5;
    background-color: #7a5070;
    color: #f4f0f5;
  }
}
.basicCardsHolder .card__image--attachment {
  background-image: url("https://images.unsplash.com/photo-1495558685573-aba7573d9c01?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2048&q=80");
}
.basicCardsHolder .card__image--anxiety {
  background-image: url("https://images.unsplash.com/photo-1595178156906-2396ef837b0f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}
.basicCardsHolder .card__image--depression {
  background-image: url("https://images.unsplash.com/photo-1613312328068-c9b6b76c9e8a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}
.basicCardsHolder .card__image--adjustment {
  background-image: url("https://images.unsplash.com/photo-1586162544998-979c72b307bc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}
.basicCardsHolder .card__image--trauma {
  background-image: url("https://images.unsplash.com/photo-1627305412843-61b0824e6f59?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}
.basicCardsHolder .card__image--communication {
  background-image: url("https://images.unsplash.com/photo-1683209616453-619caef10a00?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}
.basicCardsHolder .card__image--life {
  background-image: url("https://images.unsplash.com/photo-1581573950452-5a438c5f390f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2029&q=80");
}
.basicCardsHolder .card__image--relationship {
  background-image: url("https://images.unsplash.com/photo-1541089255538-4ea146c8866d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}
.basicCardsHolder .card__image--lgbt {
  background-image: url("https://images.unsplash.com/photo-1620862658014-b7cb662d3df4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}
.basicCardsHolder .card__image--kink {
  background-image: url("https://images.unsplash.com/photo-1583876677554-5a233be562ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}

.basicFullWidthText {
  width: 80%;
  margin: auto;
  padding: 30px;
  font-size: 1.5rem;
  line-height: 2;
  font-weight: bold;
  font-family: "Playfair Display", serif;
}
@media (max-width: 1000px) {
  .basicFullWidthText {
    font-size: 1rem !important;
  }
}

.framedImage {
  display: grid;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.124);
  grid-template-columns: max-content auto;
  grid-template-rows: auto max-content;
  background: #333 url("../Images/ashleyPicRecent.jpg") top;
  background-size: cover;
  width: 700px;
  height: 700px;
  padding: 1.8rem;
  font-family: Poppins, sans-serif;
  border-radius: 30px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}
.framedImage:before, .framedImage:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border: 3px solid #f4f0f5;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.framedImage:before {
  grid-row: 1/2;
  border-right: none;
  border-bottom: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 0px;
}
.framedImage:after {
  grid-row: 1/span 2;
  border-left: none;
}
.framedImage .frameNote {
  margin: 1rem 1rem -0.5rem 0;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
  font-size: 2rem;
  max-width: 100%;
  color: #7a5070;
  background-color: #f4f0f5;
  padding: 20px 10px;
  border: 2px solid #f4f0f5;
  border-radius: 30px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.scheduleSection {
  background: url("../Images/background.png");
  font-family: Poppins, sans-serif;
  color: white;
  padding: 50px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.scheduleSection h2 {
  align-items: center;
  text-transform: uppercase;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 40px;
}
.scheduleSection h4 {
  width: 60%;
  font-size: 1rem;
  text-align: center;
  margin: 60px 0px;
}
.scheduleSection .buttonWrapper a {
  display: block;
  font-size: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-decoration: none;
  color: #f4f0f5;
  border-top: 2px solid #f4f0f5;
  border-bottom: 2px solid #f4f0f5;
  padding: 10px;
  padding-top: 16px;
  letter-spacing: 2px;
  transition: all 0.25s;
  text-transform: uppercase;
  cursor: pointer;
}
.scheduleSection .buttonWrapper a:hover {
  letter-spacing: 5px;
  text-decoration: underline;
  text-decoration-color: #4b2140;
  background-color: #f4f0f5;
  color: #4b2140;
}

@media (max-width: 702px) {
  .scheduleSection h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .scheduleSection h4 {
    width: 70%;
    font-size: 0.8rem;
    margin: 30px 0px;
  }
}
#insuranceHeader {
  background: url("../Images/insuranceBackground.png");
  background-position: center;
  background-size: contain;
  font-family: Poppins, sans-serif;
  color: #4b2140;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  background-color: #f4f0f5;
  text-align: center;
}
#insuranceHeader h2 {
  width: 90%;
  margin: auto;
  font-size: 4.5rem;
  text-transform: uppercase;
  margin-bottom: 30px;
}
#insuranceHeader h4 {
  font-size: 2rem;
  margin-bottom: 30px;
  width: 80%;
}
#insuranceHeader h4 a {
  color: #6B2D5C;
}

#insuranceTable {
  background-color: #f4f0f5;
  margin: 0px auto;
  padding: 40px;
  font-family: Poppins, sans-serif;
  color: #6B2D5C;
}
#insuranceTable .sectionTitle {
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #7a5070;
  padding: 20px 0px;
  padding-left: 30px;
  font-weight: lighter;
  background-color: #f4f0f5;
  border-bottom: 1px solid #E5E6E4;
  margin-top: 10px;
}
#insuranceTable .sectionTitle .sectionSubtitle {
  font-size: 1.2rem;
  margin-left: 10rem;
}
#insuranceTable #insuranceTableTable {
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  border-radius: 20px;
  border: 3px solid #E5E6E4;
  border-radius: 20px !important;
  width: 80%;
  margin: 50px auto;
}
#insuranceTable #insuranceTableTable:hover {
  box-shadow: none;
  background-color: white;
}
#insuranceTable #insuranceTableTable th, #insuranceTable #insuranceTableTable td {
  padding: 20px;
  color: #6B2D5C;
}

@media (max-width: 700px) {
  #insuranceHeader {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #homeAboutMeQuickLinksSubtitle {
    margin-left: 0px !important;
  }
  #insuranceTableTable {
    margin: 50px 5px !important;
    width: 400px !important;
  }
}
@media (max-width: 500px) {
  #insuranceHeader {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #insuranceHeader h2 {
    font-size: 2rem;
  }
  #insuranceHeader h4 {
    font-size: 1.4rem;
  }
  #homeAboutMeQuickLinksSubtitle {
    margin-left: 0px !important;
  }
  #insuranceTableTable {
    margin: 50px auto !important;
    margin-left: -15px !important;
    width: 200px !important;
  }
  #insuranceTableTable th, #insuranceTableTable td {
    font-size: smaller;
    padding: 10px !important;
  }
}
.formHolder {
  color: #6B2D5C;
  font-family: Poppins, sans-serif;
  background-color: #f4f0f5;
  margin: auto;
  margin-bottom: 60px;
  width: 90%;
  /* clicked CSS */
}
.formHolder .formTitle {
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #7a5070;
  padding: 20px 0px;
  padding-left: 30px;
  font-weight: lighter;
  background-color: #f4f0f5;
  border-bottom: 1px solid #E5E6E4;
  margin-top: 10px;
  margin-bottom: 60px;
}
.formHolder .formTitle .sectionSubtitle {
  font-size: 1.2rem;
  margin-left: 10rem;
}
.formHolder #formField {
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.formHolder div.inputBox {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 100px;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  position: relative;
}
.formHolder .inputAndTitle {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 20px;
  color: #4b2140;
  flex-wrap: wrap;
  min-width: 300px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.formHolder .inputBox input {
  left: 20px;
  position: absolute;
  width: 100%;
  padding: 10px;
  border: 1px solid #4b2140;
  border-radius: 5px;
  background: none;
  outline: none;
  color: #6B2D5C;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 400;
}
.formHolder .inputBox textarea {
  left: 20px;
  position: absolute;
  width: 100%;
  padding: 10px;
  border: 1px solid #4b2140;
  border-radius: 5px;
  background: none;
  outline: none;
  color: #6B2D5C;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}
.formHolder .inputBox span {
  position: absolute;
  left: 20px;
  padding: 10px;
  pointer-events: none;
  font-size: 1.3em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
}
.formHolder .label {
  width: 200px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
}
.formHolder .inputBox input:focus ~ span {
  transform: translateX(10px) translateY(-35px);
  font-size: 0.75em;
  transition: 0.5s;
}
.formHolder input[type=submit] {
  padding: 20px 10px;
  border: 1px solid #4b2140;
  border-radius: 5px;
  color: #6B2D5C;
  font-size: 1em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-style: normal;
  font-weight: 400;
  width: 60%;
  text-transform: uppercase;
  margin: 0 20%;
  margin-top: 30px;
}
.formHolder input[type=submit]:hover {
  color: white;
  background-color: #6B2D5C;
}
.formHolder input,
.formHolder select {
  width: 100%;
  display: inline-block;
}
.formHolder input[type=submit] {
  font-size: 1.5em;
}
.formHolder .mini-image {
  height: auto;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(206, 238, 227);
}
.formHolder .required-field {
  color: red;
}
.formHolder .focusInSpan {
  transform: translateX(10px) translateY(-35px);
  font-size: 0.75em;
}

.heroSlider .heroHeader {
  background-color: #a1859b;
  color: #f4f0f5;
  margin: 0px;
  padding: 10px;
  margin-bottom: -20px;
}
.heroSlider .heroHeader h5 {
  text-align: center;
  font-size: 2vw;
  font-family: Poppins, sans-serif;
}
.heroSlider .heroHeader p {
  text-align: center;
  font-size: 1vw;
  font-family: "Playfair Display", serif;
  padding-bottom: 10px;
}
.heroSlider .carousel img {
  height: 70vh;
}

.imageTextSection {
  display: flex;
  flex-direction: row;
  padding: 60px;
  background-color: white;
  justify-content: space-around;
  align-items: center;
  color: #4b2140;
  font-family: Poppins, sans-serif;
  border-top: 1px solid #E5E6E4;
  border-radius: 30px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  width: 98%;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 80px;
}
.imageTextSection #mapHolder {
  min-width: 400px;
  height: 500px;
  border: 2px solid #a1859b;
  border-radius: 20px;
}
.imageTextSection #telehealthImage {
  width: 600px;
  height: 500px;
  border: 2px solid #a1859b;
  border-radius: 20px;
}
.imageTextSection #imageTextSectionText {
  padding: 30px;
  padding-left: 60px;
}
.imageTextSection #imageTextSectionText h2 {
  font-size: 2.5rem;
  font-family: Poppins, sans-serif;
  margin-bottom: 20px;
  color: #7a5070;
}
.imageTextSection #imageTextSectionText h3 {
  font-size: 1.5rem;
  font-family: Poppins, sans-serif;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 10px 0px;
  color: #7a5070;
  border-bottom: 1px solid #E5E6E4;
}
.imageTextSection #imageTextSectionText h3 i {
  font-style: italic;
  color: #6b4662;
}
.imageTextSection #imageTextSectionText h4 {
  font-size: 1.4rem;
  font-family: "Playfair Display", serif;
  margin-left: 60px;
  margin-bottom: 10px;
  font-weight: bold;
}

@media (max-width: 1345px) {
  #imageTextSectionText h2 {
    font-size: 2rem !important;
  }
  #imageTextSectionText h3 {
    font-size: 1rem !important;
  }
  #imageTextSectionText h4 {
    font-size: 1rem !important;
  }
  #mapHolder, #telehealthImage {
    width: 300px !important;
    height: 300px !important;
    object-fit: cover !important;
  }
}
@media (max-width: 900px) {
  .imageTextSection {
    width: 90%;
    flex-direction: column;
    justify-content: center;
  }
  #imageTextSectionText {
    padding: 0px !important;
    margin: 0px;
    margin-top: 10px;
    width: 100%;
  }
  #imageTextSectionText h2, #imageTextSectionText h4, #imageTextSectionText h3 {
    margin: 0px !important;
    margin-bottom: 10px !important;
    padding: 0px;
  }
  #imageTextSectionText h2 {
    font-size: 1.3rem !important;
  }
  #imageTextSectionText h4, #imageTextSectionText h5 {
    font-size: 1rem !important;
  }
}
@media (max-width: 400px) {
  #mapHolder {
    min-width: 300px !important;
  }
}
.textAndCarousel {
  display: flex;
  flex-direction: row;
  padding: 30px;
  background-color: white;
  justify-content: space-around;
  align-items: center;
  color: #4b2140;
  font-family: Poppins, sans-serif;
  border-top: 1px solid #E5E6E4;
  border-radius: 30px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width: 1445px) {
  .textAndCarousel {
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  .textAndCarousel .carouselHalfWidth {
    margin-bottom: 60px;
    margin-right: 0px !important;
  }
  .textAndCarousel .textHalfWidth h2 {
    margin-left: 0px !important;
    font-size: 1.5rem !important;
  }
  .textAndCarousel .textHalfWidth .text {
    margin-left: 0px !important;
    font-size: 1rem !important;
  }
}
@media (max-width: 689px) {
  .textAndCarousel .carouselHalfWidth {
    width: 300px !important;
  }
  .textAndCarousel .carouselHalfWidth img {
    height: 300px !important;
    width: 300px !important;
    object-fit: contain;
  }
  .textAndCarousel .textHalfWidth .text {
    margin-left: 0px !important;
    font-size: 0.8rem !important;
  }
}
.textAndCarousel .carouselHalfWidth {
  width: 500px;
  margin-right: 80px;
}
.textAndCarousel .carouselHalfWidth .halfCarousel img {
  height: 500px;
  width: 500px;
  object-fit: contain;
}
.textAndCarousel .textHalfWidth h2 {
  font-size: 1.8rem;
  font-family: Poppins, sans-serif;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 10px 0px;
  color: #7a5070;
  border-bottom: 1px solid #E5E6E4;
  line-height: normal;
}
.textAndCarousel .textHalfWidth h2 i {
  font-style: italic;
  color: #6b4662;
}
.textAndCarousel .textHalfWidth .text {
  margin-left: 60px;
  padding: 30px;
  font-size: 1rem;
  line-height: 2;
  font-weight: bold;
  font-family: "Playfair Display", serif;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  background-color: #f4f0f5;
  overflow-x: hidden;
}

.App {
  background-color: #f4f0f5;
}

.tab {
  display: inline-block;
  margin-left: 20px;
}

